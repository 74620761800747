import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react"
import { IconButton, CircularProgress, Skeleton, TextField, ThemeProvider, Typography, Select, MenuItem, FormGroup, Checkbox, FormControlLabel, SelectChangeEvent, Button, Modal, FormControl, InputLabel, Switch, Divider, Popover } from "@mui/material";
import { observer } from "mobx-react";
import { THEME_BUTTON, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import { t, use } from "i18next";
import SectionLayout from "../../components/student-registration-component/section-layout";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { AboriginalOrTorresIslander, CitizenInfo, CourseReason, DisabilityCondition, EducationDegree, EmploymentStatus, Gender, PaymentMethod, PaymentMethodID, PreferContactMethod, ReferredType, SchoolLevel, SectionLocation, State, VisaType, YesOrNo } from "../../constants/registration-options";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// @ts-ignore
import logo from '../../images/logo.png';
import { navigate } from "gatsby";
import RouteGuard from "../../components/route-guard";
import { FileCategory } from "../../constants/options";
import Title from "../../components/shared-title";

const UserInput = ({ children }) => {
  return <span className="font-bold underline ml-1">{children}</span>
}

const MultiUserInput = ({ children }) => {
  return <span className="font-bold mr-2">{children}</span>
}

const SummaryCheckbox = ({ checked, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled
          sx={{ color: THEME_BUTTON, '&.Mui-checked': { color: THEME_BUTTON } }} />}
      label={<Typography fontWeight="light">{label}</Typography>}
    />
  )
}

const EditableTitle = ({ children, onClick = () => { } }) => {
  return (
    <button
      type="button"
      title="Go edit"
      // @ts-ignore
      style={ITEM_PERFECT_INLINED}
      className="mb-2 border-b-4 border-white hover:border-b-highlight hover:text-highlight"
      onClick={onClick}>
      <Typography variant="h6">{children}</Typography>
      <span className="text-lg ml-2"><BorderColorIcon fontSize="inherit" /></span>
    </button>
  )
}

const SummaryPagePrint = observer(({ location }) => {
  useEffect(() => {
    console.log(location);
    
    if (!location.state || !location.state.summaryData) navigate("/traineeship/overview");
    else {
      window.print();
      navigate(-1);
    }
  }, []);


  return (
    <>
      {location.state && location.state.summaryData && <>
        <Title pageName={"Print Summary"} />
        <Box mt={2}><img style={{ width: 150, height: 60, background: '#becadb' }} src={logo} /></Box>
        <Typography variant="h5" mb={4} textAlign="center">Enroll Form Summary</Typography>

        {/* <SectionLayout title={t('PERSONAL_INFORMATION') + " Summary"} showHelp={false}> */}
        <EditableTitle>{t('PERSONAL_INFORMATION')}</EditableTitle>
        {/* <SummaryCheckbox checked={location.state.summaryData.personalInformation.singleName} label={t('SINGLE_NAME_ONLY')} /> */}
        <Box className="grid grid-cols-2 gap-4">
          <Typography fontWeight="light">First Name: <UserInput>{location.state.summaryData.personalInformation.firstName}</UserInput></Typography>
          <Typography fontWeight="light">Last Name: <UserInput>{location.state.summaryData.personalInformation.lastName}</UserInput></Typography>
          <Typography fontWeight="light">Middle Name: <UserInput>{location.state.summaryData.personalInformation.middleName}</UserInput></Typography>
          <Typography fontWeight="light">
            Gender: <UserInput>{location.state.summaryData.personalInformation.gender}</UserInput>
          </Typography>
          <Typography fontWeight="light" className="col-span-2">Email: <UserInput>{location.state.summaryData.personalInformation.email}</UserInput></Typography>
          <Typography fontWeight="light" className="col-span-2">Alternative Email: <UserInput>{location.state.summaryData.personalInformation.alternativeEmail}</UserInput></Typography>
          <Typography fontWeight="light">
            Date of Birth: <UserInput>{location.state.summaryData.personalInformation.birthDate}</UserInput>
          </Typography>
          <Typography fontWeight="light" className="col-span-2">
            Unique Student Identifier: <UserInput>{location.state.summaryData.personalInformation.uniqueStudentId}</UserInput>
          </Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <EditableTitle>{t('CONTACT_DETAILS')}</EditableTitle>
        <Box className="grid grid-cols-2 gap-4">
          <Typography fontWeight="light">Home Phone: <UserInput>{location.state.summaryData.personalInformation.contactDetails.home}</UserInput></Typography>
          <Typography fontWeight="light">Work Phone: <UserInput>{location.state.summaryData.personalInformation.contactDetails.workPhone}</UserInput></Typography>
          <Typography fontWeight="light">Mobile Phone: <UserInput>{location.state.summaryData.personalInformation.contactDetails.mobile}</UserInput></Typography>
          <Typography fontWeight="light">First Name: <UserInput>{location.state.summaryData.personalInformation.contactDetails.firstName}</UserInput></Typography>
          <Typography fontWeight="light">Last Name: <UserInput>{location.state.summaryData.personalInformation.contactDetails.lastName}</UserInput></Typography>
          <Typography fontWeight="light">Middle Name: <UserInput>{location.state.summaryData.personalInformation.contactDetails.middleName}</UserInput></Typography>
          <Typography fontWeight="light">
            Prefer Contact Method: <UserInput>{location.state.summaryData.personalInformation.contactDetails.preferContactMethod}</UserInput>
          </Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <EditableTitle>{t('ADDRESS')}</EditableTitle>
        <Box className="">
          <Typography fontWeight="light" mb={2}>Building/Property Name: <UserInput>{location.state.summaryData.personalInformation.address.buildingName}</UserInput></Typography>
          <Typography fontWeight="light" mb={2}>Street No/Unit & Building No: <UserInput>{location.state.summaryData.personalInformation.address.streetNo}</UserInput></Typography>
          <Typography fontWeight="light" mb={2}>Street Name: <UserInput>{location.state.summaryData.personalInformation.address.streetName}</UserInput></Typography>
          <Typography fontWeight="light" mb={2}>Suburb: <UserInput>{location.state.summaryData.personalInformation.address.suburb}</UserInput></Typography>
          <Typography fontWeight="light" mb={2}>
            State: <UserInput>{State.find(s => s.id == +location.state.summaryData.personalInformation.address.state) &&
              State.find(s => s.id == +location.state.summaryData.personalInformation.address.state)?.label}</UserInput></Typography>
          <Typography fontWeight="light">Postcode: <UserInput>{location.state.summaryData.personalInformation.address.postcode}</UserInput></Typography>
          <Box className="col-span-3">
            <SummaryCheckbox checked={location.state.summaryData.personalInformation.address.postalDifferent == YesOrNo.YES} label="Postal Address (if different from above)" />
          </Box>
          {location.state.summaryData.personalInformation.address.postalDifferent == YesOrNo.YES &&
            <>
              <Typography fontWeight="light">Building/Property Name: <UserInput>{location.state.summaryData.personalInformation.address.postalBuildingName}</UserInput></Typography>
              <Typography fontWeight="light">Street No/Unit & Building No: <UserInput>{location.state.summaryData.personalInformation.address.postalStreetNo}</UserInput></Typography>
              <Typography fontWeight="light">Street Name: <UserInput>{location.state.summaryData.personalInformation.address.postalStreetName}</UserInput></Typography>
              <Typography fontWeight="light">Suburb: <UserInput>{location.state.summaryData.personalInformation.address.postalSuburb}</UserInput></Typography>
              <Typography fontWeight="light">
                State: <UserInput>{State.find(s => s.id == +location.state.summaryData.personalInformation.address.postalState) &&
                  State.find(s => s.id == +location.state.summaryData.personalInformation.address.postalState)?.label}</UserInput></Typography>
              <Typography fontWeight="light">Postcode: <UserInput>{location.state.summaryData.personalInformation.address.postalPostcode}</UserInput></Typography>
            </>}
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <EditableTitle>{t('EMERGENCY_CONTACT_DETAILS')}</EditableTitle>
        <Box className="grid grid-cols-2 gap-4">
          <Typography fontWeight="light">Name: <UserInput>{location.state.summaryData.personalInformation.emergencyDetail.name}</UserInput></Typography>
          <Typography fontWeight="light">Relationship to You: <UserInput>{location.state.summaryData.personalInformation.emergencyDetail.relationship}</UserInput></Typography>
          <Typography fontWeight="light">Phone: <UserInput>{location.state.summaryData.personalInformation.emergencyDetail.phone}</UserInput></Typography>
        </Box>
        {/* </SectionLayout> */}

        <Box marginY={2} />

        {/* <SectionLayout title={t('ADMISSION_QUALIFICATIONS') + " Summary"} showHelp={false}> */}
        <EditableTitle>{t('ADMISSION_QUALIFICATIONS')}</EditableTitle>
        <Typography fontWeight="light">Are you of Aboriginal or Torres Islander orgin?: <UserInput>{location.state.summaryData.admissionQualifications.aboriginalOrTorresIslander}</UserInput></Typography>
        <Typography fontWeight="light" sx={{ marginY: 2 }}>In which country were you born?: <UserInput>{location.state.summaryData.admissionQualifications.countryBorn.id !== "" &&
          (+location.state.summaryData.admissionQualifications.countryBorn.id == 1 ? "Australia" : "Others")}</UserInput></Typography>
        {+location.state.summaryData.admissionQualifications.countryBorn.id == 2 &&
          <Box className="grid grid-cols-2 gap-4">
            <Typography fontWeight="light">Country: <UserInput>{location.state.summaryData.admissionQualifications.countryBorn.countryName}</UserInput></Typography>
            <Typography fontWeight="light">Arrive Date: <UserInput>{location.state.summaryData.admissionQualifications.countryBorn.arrivedDate}</UserInput></Typography>
          </Box>}
        <Box className="grid grid-cols-2 gap-4 mt-4">
          <Typography fontWeight="light">Do you speak a language other than English at home?: <UserInput>{location.state.summaryData.admissionQualifications.spokenLang.id}</UserInput></Typography>
          {location.state.summaryData.admissionQualifications.spokenLang.id == "Yes, other (please specify)" && <Typography fontWeight="light">Other Language: <UserInput>{location.state.summaryData.admissionQualifications.spokenLang.language}</UserInput></Typography>}
          <Typography fontWeight="light" className="col-span-2">Highest completed school level: <UserInput>{location.state.summaryData.admissionQualifications.completedEduactionLevel}</UserInput></Typography>
          <Typography fontWeight="light">Are you still enrolled in Secondary or Senior secondary education?: <UserInput>{location.state.summaryData.admissionQualifications.secondaryEducation.id == YesOrNo.NO ? "No" :
            location.state.summaryData.admissionQualifications.secondaryEducation.id == YesOrNo.YES && "Yes"}</UserInput></Typography>
          {location.state.summaryData.admissionQualifications.secondaryEducation.id == YesOrNo.YES ? <Typography fontWeight="light">Enrolled Year: <UserInput>{location.state.summaryData.admissionQualifications.secondaryEducation.enrolledYear}</UserInput></Typography>
            : <Box />}
          <Box className="col-span-2" marginBottom={2}>
            <Typography fontWeight="light">
              Have you successfully completed any of the following?:
            </Typography>
            <Box>
              {location.state.summaryData.admissionQualifications.completedEducation.map((id: number, index: number) => (
                <MultiUserInput>{EducationDegree.find(e => e.id == id)?.label}{index !=
                  location.state.summaryData.admissionQualifications.completedEducation.length - 1 && ","}</MultiUserInput>
              ))}
            </Box>
          </Box>
        </Box>
        <Typography fontWeight="light" className="col-span-2 my-4" marginBottom={2}>
          Which BEST describe your current employment status?: <UserInput>{EmploymentStatus.find(es => es.id == location.state.summaryData.admissionQualifications.employmentStatus) &&
            EmploymentStatus.find(es => es.id == location.state.summaryData.admissionQualifications.employmentStatus)?.label}</UserInput>
        </Typography>
        <Typography fontWeight="light">
          Do you consider yourself as having a disability impairment or long-term condition?: <UserInput>{location.state.summaryData.admissionQualifications.disabilityCondition.id == YesOrNo.NO ?
            "No" : location.state.summaryData.admissionQualifications.disabilityCondition.id == YesOrNo.YES && "Yes"}</UserInput>
        </Typography>
        {location.state.summaryData.admissionQualifications.disabilityCondition.id == YesOrNo.YES &&
          <>
            <Typography fontWeight="light">Would you like to recieve assistance?: <UserInput>{location.state.summaryData.admissionQualifications.disabilityCondition.needAssistance == YesOrNo.NO ? "No"
              : location.state.summaryData.admissionQualifications.disabilityCondition.needAssistance == YesOrNo.YES && "Yes"}</UserInput></Typography>
            <Typography fontWeight="light" marginTop={2}>Disability, impairment or long-term condition:</Typography>
            {location.state.summaryData.admissionQualifications.disabilityCondition.areas.map((id: number, index: number) => (
              <MultiUserInput>{DisabilityCondition.find(d => d.id == id)?.label}{index !=
                location.state.summaryData.admissionQualifications.disabilityCondition.areas.length - 1 && ","}</MultiUserInput>
            ))}
          </>}
        <Typography fontWeight="light" className="my-4" marginTop={2}>
          Citizen/Passport Visa Information Citizen Status: <UserInput>{CitizenInfo.find(c => c.id == location.state.summaryData.admissionQualifications.citizenStatus.isAustralian)
            && CitizenInfo.find(c => c.id == location.state.summaryData.admissionQualifications.citizenStatus.isAustralian)?.label}</UserInput>
        </Typography>

        {+location.state.summaryData.admissionQualifications.citizenStatus.isAustralian != 1 &&
          <Box className="grid grid-cols-2 gap-4">
            <Typography fontWeight="light">Oversea Passport No.: <UserInput>{location.state.summaryData.admissionQualifications.citizenStatus.passportNumber}</UserInput></Typography>
            <Typography fontWeight="light">Visa Expiry Date: <UserInput>{location.state.summaryData.admissionQualifications.citizenStatus.visaExpiryDate}</UserInput></Typography>
            <Typography fontWeight="light">Visa Type: <UserInput>{VisaType.find(v => v.id == location.state.summaryData.admissionQualifications.citizenStatus.visaType) &&
              VisaType.find(v => v.id == location.state.summaryData.admissionQualifications.citizenStatus.visaType)?.label}</UserInput></Typography>
          </Box>}
        <Typography fontWeight="light" className="mt-4" marginTop={2}>
          Please indicate how you were referred to Benchmark College?:
        </Typography>
        <Box>
          {location.state.summaryData.admissionQualifications.referredType.referredTypeIdList.map((id: number, index: number) => (
            <MultiUserInput>{ReferredType.find(r => r.id == id)?.label}{index !=
              location.state.summaryData.admissionQualifications.referredType.referredTypeIdList.length - 1 && ","}</MultiUserInput>
          ))}
          {location.state.summaryData.admissionQualifications.referredType.referredTypeIdList.includes(ReferredType.find(r => r.label == t('OTHER'))?.id) &&
            <MultiUserInput>{location.state.summaryData.admissionQualifications.referredType.otherReferredTypeLabel}</MultiUserInput>}
        </Box>
        <Box className="grid grid-cols-1 gap-4 my-4" marginY={2}>
          <Typography fontWeight="light">ESP/DES Name: <UserInput>{location.state.summaryData.admissionQualifications.referredType.espName}</UserInput></Typography>
          <Typography fontWeight="light">Job Seeker Identification No: <UserInput>{location.state.summaryData.admissionQualifications.referredType.jobSeekerIdentifier}</UserInput></Typography>
          <Typography fontWeight="light">Payment Method: <UserInput>{PaymentMethod.find(p => p.id == location.state.summaryData.admissionQualifications.paymentMethod.id) &&
            PaymentMethod.find(p => p.id == location.state.summaryData.admissionQualifications.paymentMethod.id)?.label}</UserInput></Typography>
          {location.state.summaryData.admissionQualifications.paymentMethod.id == PaymentMethodID.OTHER &&
            <Typography fontWeight="light">Custom Payment Method: <UserInput>{location.state.summaryData.admissionQualifications.paymentMethod.otherMethodLabel}</UserInput></Typography>}
        </Box>
        <Typography fontWeight="light" className="my-4">Main reason for doing this course: <UserInput>{CourseReason.find(c => c.id == location.state.summaryData.admissionQualifications.pickCourseReasonId) &&
          CourseReason.find(c => c.id == location.state.summaryData.admissionQualifications.pickCourseReasonId)?.label}</UserInput></Typography>
        {/* </SectionLayout> */}

        {/* <SectionLayout title={t('CREDIT_TRANSFER') + " Summary"} showHelp={false}> */}
        <EditableTitle>{t('CREDIT_TRANSFER')}</EditableTitle>
        <Typography fontWeight="light" marginBottom={2}>Do you want to apply for Recognition of Prior Learning: <UserInput>{location.state.summaryData.creditTransfer.applyPriorLearning == YesOrNo.NO ? "No" :
          location.state.summaryData.creditTransfer.applyPriorLearning == YesOrNo.YES && "Yes"}</UserInput></Typography>
        <Typography fontWeight="light" className="my-4">Do you want to apply for Credit Transfer: <UserInput>{location.state.summaryData.creditTransfer.applyCreditTransfer == YesOrNo.NO ? "No" :
          location.state.summaryData.creditTransfer.applyCreditTransfer == YesOrNo.YES && "Yes"}</UserInput></Typography>
        <Typography fontWeight="light" marginBottom={2}>Have you completed Year 12: <UserInput>{location.state.summaryData.creditTransfer.completedYearTwelve.completed == YesOrNo.NO ? "No" :
          location.state.summaryData.creditTransfer.completedYearTwelve.completed == YesOrNo.YES && "Yes"}</UserInput></Typography>
        {location.state.summaryData.creditTransfer.completedYearTwelve.completed == YesOrNo.YES && "Yes" &&
          <Box className="grid grid-cols-2 gap-4 mt-4" marginBottom={2}>
            <Typography fontWeight="light">Suburb: <UserInput>{location.state.summaryData.creditTransfer.completedYearTwelve.suburb}</UserInput></Typography>
            <Typography fontWeight="light">Postcode: <UserInput>{location.state.summaryData.creditTransfer.completedYearTwelve.postcode}</UserInput></Typography>
            <Typography fontWeight="light">Year completed: <UserInput>{location.state.summaryData.creditTransfer.completedYearTwelve.yearCompleted}</UserInput></Typography>
          </Box>}
        <Typography fontWeight="light" className="mt-4">Have you previously accessed FEE-HELP for any VET or Higher Education courses: <UserInput>{location.state.summaryData.creditTransfer.feeHelpAccessed.accessed == YesOrNo.NO ? "No" :
          location.state.summaryData.creditTransfer.feeHelpAccessed.accessed == YesOrNo.YES && "Yes"}</UserInput></Typography>
        {location.state.summaryData.creditTransfer.feeHelpAccessed.accessed == YesOrNo.YES &&
          <Typography fontWeight="light" className="my-4">Your Commonwealth Student Number (CHESSN): <UserInput>{location.state.summaryData.creditTransfer.feeHelpAccessed.chessn}</UserInput></Typography>}
        {/* </SectionLayout> */}

        {/* <SectionLayout title={"Enrol Qualification Summary"} showHelp={false}> */}
        <EditableTitle>Enrol Qualification</EditableTitle>
        <Typography fontWeight="light">Qualification: <UserInput>{location.state.summaryData.enrolQualification.qualificationName}</UserInput></Typography>
        <Box className="grid grid-cols-1 gap-4 my-4">
          <Typography fontWeight="light">Group Name: <UserInput>{location.state.summaryData.enrolQualification.groupName}</UserInput></Typography>
          <Typography fontWeight="light">Employer: <UserInput>{location.state.summaryData.enrolQualification.orgName}</UserInput></Typography>
        </Box>
        {/* </SectionLayout> */}
        {location.state.uploadedFileList &&
          <>
            <EditableTitle>IDs:</EditableTitle>
            {JSON.parse(location.state.uploadedFileList).length > 0 ?
              JSON.parse(location.state.uploadedFileList).filter((f: any) => f.category == FileCategory.FORM_PERSONAL_ID)
                .map((f: any) => (
                  <Box sx={ITEM_PERFECT_INLINED} mb={2}>
                    <Box className="border-r p-2 pr-4">
                      <InsertDriveFileOutlinedIcon fontSize="large" />
                    </Box>
                    <Box paddingX={2}>
                      <Typography>{f.documentName}</Typography>
                      <Typography variant="caption" color="gray">{f.uploadTime.replace('T', ' ')}</Typography>
                    </Box>
                  </Box>
                )) : <Typography>No ID files uploaded</Typography>}

            <EditableTitle>Declaration:</EditableTitle>
            {JSON.parse(location.state.uploadedFileList).length > 0 ?
              JSON.parse(location.state.uploadedFileList).filter((f: any) => f.category == FileCategory.FORM_SIGNATURE)
                .map((f: any) => (
                  <Box sx={ITEM_PERFECT_INLINED} mb={2}>
                    <Box className="border-r p-2 pr-4">
                      <InsertDriveFileOutlinedIcon fontSize="large" />
                    </Box>
                    <Box paddingX={2}>
                      <Typography>{f.documentName}</Typography>
                      <Typography variant="caption" color="gray">{f.uploadTime.replace('T', ' ')}</Typography>
                    </Box>
                  </Box>
                )) : <Typography>No declaration files uploaded</Typography>}
          </>}

        {/* <SectionLayout title={"Upload ID & Decorations"} showHelp={false}>
          <Typography fontWeight="light"><Typography color="red">IMPORTANT: </Typography>Please upload your ID & decoration file before submitting the form</Typography>
          <Box className="grid grid-cols-2 gap-8 mt-4 mb-2">
            <Button
              variant="outlined"
              component="label"
              sx={{ display: 'block' }}
              className="border-4 rounded-xl border-dashed py-4 text-center"
              disabled>
              {fileStore.uploadingID ? <CircularProgress sx={{ marginTop: 2 }} color="inherit" />
                : <>
                  <FileUploadOutlinedIcon fontSize="large" />
                  <Typography marginTop={1} variant="body1">Upload Your ID Here</Typography>
                  <input hidden type="file" onChange={(file: any) => uploadFile(file.target.files[0], FileCategory.FORM_PERSONAL_ID)} />
                </>}
            </Button>
            <Button
              variant="outlined"
              component="label"
              sx={{ display: 'block' }}
              className="border-4 rounded-xl border-dashed py-4 text-center"
              disabled>
              {fileStore.uploadingDecorat ? <CircularProgress sx={{ marginTop: 2 }} color="inherit" />
                : <>
                  <FileUploadOutlinedIcon fontSize="large" />
                  <Typography marginTop={1} variant="body1">Upload Your Decoration File Here</Typography>
                  <input hidden type="file" onChange={(file: any) => uploadFile(file.target.files[0], FileCategory.FORM_SIGNATURE)} />
                </>}
            </Button>

            <Box>
                <Typography variant="h6">Number of Uploads: ({location.state.fileList && location.state.fileList.length > 0 ?
                  location.state.fileList.filter((f: any) => f.category == FileCategory.FORM_PERSONAL_ID).length : 0})</Typography>
                {location.state.fileList.length > 0 &&
                  location.state.fileList.filter((f: any) => f.category == FileCategory.FORM_PERSONAL_ID)
                    .map((f: any) => (
                      <Box sx={ITEM_PERFECT_INLINED} marginY={1}>
                        <Button variant="outlined" disabled>
                          {fileStore.loading ? <CircularProgress />
                            : <Box sx={ITEM_PERFECT_INLINED}>
                              <Box className="border-r p-2 pr-4">
                                <InsertDriveFileOutlinedIcon fontSize="large" />
                              </Box>
                              <Box paddingX={2}>
                                <Typography>{f.documentName}</Typography>
                                <Typography variant="caption" color="gray">{f.uploadTime.replace('T', ' ')}</Typography>
                              </Box>
                            </Box>}
                        </Button>
                        <Button disabled>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    ))}
              </Box>
              <Box>
                <Typography variant="h6">Number of Uploads: ({location.state.fileList && location.state.fileList.length > 0 ?
                  location.state.fileList.filter((f: any) => f.category == FileCategory.FORM_SIGNATURE).length : 0})</Typography>
                {location.state.fileList.length > 0 &&
                  location.state.fileList.filter((f: any) => f.category == FileCategory.FORM_SIGNATURE)
                    .map((f: any) => (
                      <Box sx={ITEM_PERFECT_INLINED} marginY={1}>
                        <Button variant="outlined" disabled={fileStore.loading}>
                          {fileStore.loading ? <CircularProgress />
                            : <Box sx={ITEM_PERFECT_INLINED}>
                              <Box className="border-r p-2 pr-4">
                                <InsertDriveFileOutlinedIcon fontSize="large" />
                              </Box>
                              <Box paddingX={2}>
                                <Typography>{f.documentName}</Typography>
                                <Typography variant="caption" color="gray">{f.uploadTime.replace('T', ' ')}</Typography>
                              </Box>
                            </Box>}
                        </Button>
                        <Button disabled variant="outlined" color="error" size="large" sx={{ marginLeft: 1, height: 64 }}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    ))}
              </Box>
          </Box>
        </SectionLayout> */}
      </>}
    </>
  )
});

export default SummaryPagePrint;
